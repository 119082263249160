<!--  -->
<template>
  <div class="approval-li">
    <div class="row1">
      <div class="title">{{ info.staffName }}的用车审批</div>
      <div class="right">
        <div
          class="back"
          @click.prevent.stop="goBackCar"
          v-if="1 == type && 1 == info.approveStatus && 1 == info.status"
        >
          还车
        </div>
        <div
          :class="{
            doing: true,
            approving: 0 == info.approveStatus,
            accept: 1 == info.approveStatus,
            reject: 2 == info.approveStatus,
            cancel: 3 == info.approveStatus,
          }"
        >
          {{ info.approveStatus | formatApproveStatus }}
        </div>
      </div>
    </div>

    <div class="msg">
      <span>申请车辆:{{ info.platingNumber }}</span>
    </div>

    <div class="row5">
      <div class="submit-from">由 {{ info.staffName }} 提交</div>
      <div class="time">
        {{ info.createTime }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "approvalList",
  components: {},
  props: {
    info: Object,
    type: String,
  },
  computed: {
    designValue() {},
  },
  filters: {
    formatApproveStatus(val) {
      const rules = {
        0: "审批中",
        1: "审批通过",
        2: "审批驳回",
        3: "已撤销",
      };
      return rules[val] || val;
    },
  },
  mounted() {},
  methods: {
    goBackCar() {
      this.$router.push({
        path: "/carBack",
        query: {
          platingNumber: this.info.platingNumber,
          cartCode: this.info.cartCode,
          applyCode: this.info.applyCode,
          useCard: this.info.cardType,
          cardNo: this.info.cardNo,
          cardName: this.info.cardVO ? this.info.cardVO.cardName : "",
          cardType: this.info.cardVO ? this.info.cardVO.cardType : "",
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.approval-li {
  width: 7.1rem;
  background: #ffffff;
  border-radius: 0.2rem;
  margin-bottom: 0.2rem;
  margin-left: 0.2rem;
  padding: 0.2rem;
  .row1 {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 0.3rem;
      font-weight: 600;
      color: #333333;
      line-height: 0.42rem;
    }
    .right {
      display: flex;
      align-items: center;
      .back {
        border-radius: 0.08rem;
        font-size: 0.26rem;
        line-height: 0.36rem;
        padding: 0.06rem 0.12rem;
        margin-left: 0.2rem;
        border: 1px solid #999;
      }
      .doing {
        border-radius: 0.08rem;
        font-size: 0.26rem;
        color: #fff;
        line-height: 0.36rem;
        padding: 0.06rem 0.12rem;
        margin-left: 0.2rem;
      }

      .approving {
        background: #1989fa;
      }
      .reject {
        background: #ee0a24;
      }
      .accept {
        background: #07c160;
      }

      .cancel {
        background: #999;
      }
    }
  }
  .msg {
    word-break: break-all;
    font-size: 0.26rem;
    color: #999999;
    line-height: 0.37rem;
    margin-bottom: 0.12rem;
  }
  .row5 {
    display: flex;
    align-items: center;
    .submit-from {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #333333;
      line-height: 0.37rem;
      flex: 1;
      white-space: nowrap;
      padding: 0.06rem 0;
    }

    .time {
      height: 0.3rem;
      font-size: 0.22rem;
      color: #999999;
      line-height: 0.3rem;
      white-space: nowrap;
    }
  }
}
</style>
