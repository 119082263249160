<!--  -->
<template>
  <div class="approvalList">
    <div class="top">
      <van-form>
        <div class="search-box">
          <img :src="searchIcon" alt="" class="search-icon" @click="search" />
          <input
            type="text"
            v-model="queryParams.searchContent"
            placeholder="请输入关键字"
            class="search-input"
          />
          <img :src="closeIcon" alt="" class="close-icon" @click="clearInput" />
        </div>
      </van-form>

      <div class="filter" @click="search">
        <img :src="filterIcon" alt="" class="filter-icon" />
        <span class="text">搜索</span>
      </div>
    </div>
    <van-tabs v-model="queryParams.type" @change="changeTab">
      <van-tab title="我的预约" name="1"> </van-tab>
      <!-- <van-tab title="待我审批的" name="0"> </van-tab>
      <van-tab title="审批记录" name="99"> </van-tab> -->
    </van-tabs>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="handleLoad"
      v-if="list && list.length"
    >
      <ul class="approval-ul">
        <li v-for="(item, index) in list" :key="index" @click="goDetail(item)">
          <use-item :info="item" :type="queryParams.type" />
        </li>
      </ul>
    </van-list>
    <van-empty v-else description="暂无数据" :image-size="60" />
  </div>
</template>

<script>
import { useApplyList, getMyApproveHistory } from "@/api/cart/manage/index.js";
import UseItem from "./components/UseItem.vue";
import closeIcon from "@/assets/images/approveList/closeIcon.png";
import searchIcon from "@/assets/images/approveList/searchIcon.png";
import filterIcon from "@/assets/images/approveList/filterIcon.png";

export default {
  data() {
    return {
      list: [],
      searchIcon,
      closeIcon,
      filterIcon,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: "0",
      },
      active: "1",
      loading: false,
      finished: false,
    };
  },
  name: "useList",
  components: { UseItem },
  computed: {},
  filters: {},
  mounted() {
    let type = this.$route.query.type;
    if (type) {
      this.queryParams.type = type;
    }
    this.getList();
  },

  methods: {
    getList() {
      if ("1" == this.queryParams.type) {
        this.getUseList();
      } else if ("0" == this.queryParams.type) {
        this.getUseList();
      } else {
        this.getApproveList();
      }
    },
    getUseList() {
      useApplyList({
        ...this.queryParams,
      })
        .then((res) => {
          this.list = this.list.concat(res.data.records);
          this.queryParams.pageNum++;
          if (this.list.length >= res.data.total) {
            this.finished = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getApproveList() {
      getMyApproveHistory({
        ...this.queryParams,
      })
        .then((res) => {
          this.list = this.list.concat(res.data.records);
          this.queryParams.pageNum++;
          if (this.list.length >= res.data.total) {
            this.finished = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    clearInput() {
      this.queryParams.searchContent = "";
      this.list = [];
      this.queryParams.pageNum = 1;
      this.finished = false;
      this.getList();
    },
    goDetail(item) {
      let path = "/applyDetail";
      if (item.tagKey && item.tagKey == "CAR_APPLY_APPROVE") {
        path = "/approvalCarDetail";
      }
      this.$router.push({
        path: path,
        query: {
          applyCode: item.applyCode,
          type: this.queryParams.type,
          tagKey: item.tagKey,
          bizCode: item.applyCode,
          processCode: item.processCode,
        },
      });
    },
    handleLoad() {
      this.getList();
    },
    search() {
      this.list = [];
      this.queryParams.pageNum = 1;
      this.finished = false;
      this.getList();
    },

    changeTab() {
      this.list = [];
      this.queryParams.pageNum = 1;
      this.finished = false;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.approvalList {
  min-height: 100%;
  background: #fafafa;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  .top {
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-box {
      width: 5.92rem;
      height: 0.6rem;
      background: #ffffff;
      border-radius: 0.3rem;
      border: 1px solid #999999;
      display: flex;
      align-items: center;
      .search-icon {
        width: 0.24rem;
        height: 0.24rem;
        margin-left: 0.18rem;
        margin-right: 0.08rem;
      }
      .search-input {
        flex: 1;
        height: 0.4rem;
        background: #ffffff;
        border: none;
        width: 2rem;
      }
      .close-icon {
        height: 0.24rem;
        margin-right: 0.18rem;
      }
    }
    .filter {
      padding: 0 0.1rem;
      .filter-icon {
        widows: 0.24rem;
        height: 0.24rem;
        margin-right: 0.04rem;
      }
      .text {
        height: 0.3rem;
        font-size: 0.28rem;
        font-weight: 500;
        color: #666666;
        line-height: 0.3rem;
      }
    }
  }
  .approval-ul {
    margin-top: 0.2rem;
  }
}
</style>
