var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"approval-li"},[_c('div',{staticClass:"row1"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.info.staffName)+"的用车审批")]),_c('div',{staticClass:"right"},[(1 == _vm.type && 1 == _vm.info.approveStatus && 1 == _vm.info.status)?_c('div',{staticClass:"back",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goBackCar.apply(null, arguments)}}},[_vm._v(" 还车 ")]):_vm._e(),_c('div',{class:{
          doing: true,
          approving: 0 == _vm.info.approveStatus,
          accept: 1 == _vm.info.approveStatus,
          reject: 2 == _vm.info.approveStatus,
          cancel: 3 == _vm.info.approveStatus,
        }},[_vm._v(" "+_vm._s(_vm._f("formatApproveStatus")(_vm.info.approveStatus))+" ")])])]),_c('div',{staticClass:"msg"},[_c('span',[_vm._v("申请车辆:"+_vm._s(_vm.info.platingNumber))])]),_c('div',{staticClass:"row5"},[_c('div',{staticClass:"submit-from"},[_vm._v("由 "+_vm._s(_vm.info.staffName)+" 提交")]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.info.createTime)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }